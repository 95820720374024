<template lang="pug">
b-container(fluid)
  b-row
    b-col
      h3.mb-3 QRs
    b-col(cols="auto")
      b-button(variant="success", @click="createQRModal = true;") Crear qr
  b-table(
    :items="qrs",
    :fields="fields",
    responsive,
    hover,
    no-border-collapse,
    @row-clicked="openRow",
    show-empty
  )
    template(#empty)
      .text-center No hay datos para mostrar
    template(#cell(createdAt)="{item}")
      span {{ $moment(item.createdAt).format('DD MMMM YYYY, HH:mmA') }}
    template(#cell(updatedAt)="{item}")
      span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}
    template(#cell(name)="{item}")
      span(:class="item.name ? null : 'text-secondary'") {{ item.name || 'Sin nombre' }}
    template(#cell(status)="{item}")
      b-badge.text-capitalize(:variant="getStatusVariant(item.status)", pill) {{ item.status == 'pendiente' ? 'Disponible' : item.status }}
    template(#cell(active)="{item}")
      component.icon-md(:is="item.active ? 'check-circle-icon' : 'x-circle-icon'", :class="item.active ? 'text-success' : 'text-danger'")
  .d-flex.align-items-center.mb-3
    b-pagination.mb-0(
      v-model="paginationPage",
      :total-rows="paginationTotal",
      :per-page="paginationLimit",
      @input="getQRs"
    )
    b-spinner.ml-3(variant="primary", small, v-if="loadingData")

  b-modal(v-model="createQRModal", hide-footer)
    b-container
      b-row
        b-col(cols="12")
          validation-observer(v-slot="{invalid, handleSubmit}")
            b-form(@submit.prevent="handleSubmit(createQR)")
              b-form-group(label="Nombre de QR")
                validation-provider(
                  name="nombre de QR",
                  rules="min:1",
                  v-slot="{ errors }"
                )
                  b-form-input(
                    placeholder="Nombre de QR",
                    name="name",
                    v-model="newQRName",
                    :state="errors[0] ? false : null"
                  )
                  b-form-invalid-feedback {{ errors[0] }}

              .text-right.mt-3
                b-button(variant="success", :disabled="invalid || loadingData", type="submit") Crear QR

  b-modal(v-model="editQRModal", hide-footer, :title="qrName")
    b-container(v-if="qrObject")
      qr-viewer(:qrCode="qrObject.qr_code")
      .mt-3.text-right
        b-button.text-danger(variant="link", @click="deleteQR") Eliminar QR
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const qrController = createNamespacedHelpers("qr");

import QRViewer from '@/components/QR.vue'

export default {
  data() {
    return {
      newQRName: "",
      createQRModal: false,
      fields: [
        {
          key: "qr_id",
          label: "Id",
        },
        {
          key: "name",
          label: "Nombre QR",
        },
        'status',
        {
          key: 'active',
          label: 'Activo'
        },
        {
          key: "updatedAt",
          label: "Actualizado",
        },
        {
          key: "createdAt",
          label: "Creado",
        },
      ],
      editQRModal: false,
      qrObject: null
    }
  },
  computed: {
    ...qrController.mapState({
      qrs: (state) => state.qrs,
    }),
    qrName () {
      if (this.qrObject) {
        return this.qrObject.qr_id + '. ' + (this.qrObject.name || 'Sin nombre')
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions(["qr/all", 'qr/create', 'qr/destroy']),
    getStatusVariant(status) {
      switch (status) {
        case "entrada":
          return "success";
        case "salida":
          return "danger";
        default:
          return "secondary";
      }
    },
    getQRs() {
      this.loadingData = true;
      this["qr/all"]({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit,
          qr_type: 'registro'
        },
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            this.paginationTotal = res.total;
            this.paginationHasNextPage = res.hasNextPage;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los qrs. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    createQR() {
      this.loadingData = true;
      this["qr/create"]({
        name: this.newQRName,
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            this.$bvToast.toast(`QR creado con éxito.`, {
              title: "Creado",
              variant: "success",
            });

            this.createQRModal = false;
            this.getQRs()

            setTimeout(() => {
              this.openRow(res.resource)
            }, 1000);
          } else {
            this.$bvToast.toast(
              `Ocurrió un error creando qr. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    openRow(item) {
      this.qrObject = item
      this.editQRModal = true;
    },
    deleteQR () {
      this.$bvModal
        .msgBoxConfirm("¿Deseas eliminar el qr? No se puede deshacer.", {
          okVariant: 'danger',
          okTitle: 'Eliminar'
        })
        .then((value) => {
          if (value == true) {
            this.loadingData = true;
            this['qr/destroy']({
              qr_id: this.qrObject.qr_id,
              callback: res => {
                this.loadingData = false;
                if (res.success) {
                  this.$bvToast.toast(`QR eliminado con éxito.`, {
                    title: 'Eliminado',
                    variant: 'success'
                  })

                  this.getQRs()
                  this.editQRModal = false;
                } else {
                  this.$bvToast.toast(`Ocurrió un error eliminando el qr. Por favor intenta de nuevo.`, {
                    title: 'Ocurrió un error',
                    variant: 'danger'
                  })
                }
              }
            })
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted() {
    this.getQRs();
  },
  components: {
    'qr-viewer': QRViewer
  }
};
</script>

<style>
</style>